import React, { useState, useEffect, useRef } from "react";
import "./topnav.scss"; // Ensure this file has styles for the new design
import UserInfo from "../user-info/UserInfo";
import { TbMenuDeep } from "react-icons/tb";
import { useStoreContext } from "../../context/StoreContext";
import { FaCaretDown } from "react-icons/fa"; // Import dropdown icon
import { useNavigate } from "react-router-dom";

const TopNav = () => {
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { store, user, changeStore, error } = useStoreContext();
  const dropdownRef = useRef(null); // Reference to the dropdown menu
  const navigate = useNavigate();
  const selectStore = (newStoreId) => {
    setLoading(true);
    changeStore(newStoreId);
    setIsDropdownOpen(false); // Close dropdown after selection
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const addStore = () => {
    navigate("/addstore"); // Redirect to add store page
  };

  const toggleSidebar = () => {
    document.body.classList.toggle("sidebar-open"); // Toggle sidebar open/close
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  if (error) {
    return (
      <div className="topnav">
        <div className="error-message">Error loading data.</div>
      </div>
    );
  }

  return (
    <div className="topnav">
      {loading && <div className="loading-screen">Switching Store...</div>}

      <div className="store-dropdown" ref={dropdownRef}>
        <button onClick={toggleDropdown} className="dropdown-button">
          {store?.StoreName.slice(0, 20) || "Select Store"}
          <FaCaretDown className="dropdown-icon" /> {/* Dropdown icon */}
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            {user?.stores?.map((storeId) => (
              <button
                key={storeId}
                onClick={() => selectStore(storeId)}
                className={`store-option ${
                  storeId === store?.id ? "active" : ""
                }`}
              >
                StoreId {storeId}
              </button>
            ))}
            <button onClick={addStore} className="add-store-button">
              Add Store
            </button>
          </div>
        )}
      </div>

      {/* Show menu icon only on small devices */}
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        <span className="icon">
          <TbMenuDeep />
        </span>
      </div>
    </div>
  );
};

export default TopNav;
