import React from "react";
import logo from "../../assets/images/NOTATMRP.png";
import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import LoginModel from "./homeComp/LoginModal";
import useLogout from "../../service/useLogout";
import { Link } from "react-router-dom";
export default function Navbar({ user }) {
  const siginmodel = useDisclosure();
  const { handleLogout, loading } = useLogout();

  return (
    <>
      <div className="border-[#7415A2] p-4 md:p-3 shadow-lg text-[#ffffff]">
        <div className="flex justify-between">
          <Link to="/">
            <img
              src={logo}
              alt="Not@Mrp Logo"
              className="cursor-pointer w-[200px] h-[60px] object-contain"
            />
          </Link>
          {user ? (
            <Menu>
              <MenuButton>
                <Box display="flex" alignItems="center">
                  <Avatar src={user.profilePicture} size="sm" mr={2} />
                </Box>
              </MenuButton>
              <MenuList>
                <MenuItem
                  color="purple"
                  onClick={handleLogout}
                  isDisabled={loading}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <button
              onClick={siginmodel.onOpen}
              className="bg-purple-700 text-white px-2 font-sans font-[700] hover:bg-purple-900  rounded-lg"
            >
              Login/Signup
            </button>
          )}
        </div>
      </div>

      <LoginModel onClose={siginmodel.onClose} isOpen={siginmodel.isOpen} />
    </>
  );
}
